import React from 'react';

const BookingForm = () => {
    return (
        <div className="booking-form-container"> {/* Added a container for styling */}
            <h2>Booking Form</h2>
            <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLScM0_DW1_u8XiRBrlq6qwvg7N8cGcNzwXxHuunLjsg4WZ_RUA/viewform?embedded=true"
                width="640"
                height="959"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
            >
                Loading…
            </iframe>
        </div>
    );
};

export default BookingForm;
